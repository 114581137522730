<template>
  <h1 class="brand-header">Got it!</h1>
  <img src="./images/Got_It_partial_light.png" class="header-image" width="563" height="650">
  <p>An accountability / checkin service.</p>

  <button type="link" class="buy-now" onclick="window.location.href='https://buy.stripe.com/00gg1d9qzc3d2xa5kk';"  href="https://buy.stripe.com/00gg1d9qzc3d2xa5kk">Sign up now</button>
  <p>We're just starting! <i>Full refund any time within 90 days</i></p>
  <p class="coupon-CTA">Use code <pre>CLUBHOUSE<span class="standout">ND</span></pre> for <strong>75%</strong> <strong>clubhouse-friend</strong> early-adopter <strong>discount!</strong></p>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;

}

img {
  width: 70%;
  height: auto;
  aspect-ratio: attr(width) / attr(height);
}

.brand-header {
  font-size: xxx-large;
  color: #4CAF50;
}

.header-image {
  /*width: 10em; */
  min-width: 300px;
  max-width: 563px;
}

button {
  cursor:pointer;
}

button.buy-now {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 32px;
}

.coupon-CTA {
  text-decoration: none;
  font-style: italic;
}

pre {
  display: inline;
  background: black;
  color: white;
}

.standout {
  color: aquamarine;
}

pre::before {
  content: "  ";
}

pre::after {
  content: "  ";
}
</style>
